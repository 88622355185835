import styled from 'styled-components';



export const H1 = styled.h1`
    font-size: 1.8rem;
    /* font-size: 1.6vw; */

    @media screen and (max-width:960px){
        font-size:1.4rem
    }
`

export const H2 = styled.h2`
    font-size: 1.4rem;
    /* font-size: 1.2vw; */
`

export const P = styled.p`
    font-size: 1.2rem;
    @media screen and (max-width: 960px){
        text-align: center;
    font-size: .8rem;
    }
`